'use client';

import { useRouter } from 'next/navigation';
import posthog from 'posthog-js';

import Button from 'components/buttons/button';
import signout from 'images/icons/log-out.svg';
import fetchJson from 'lib/fetchJson';
import useUser from 'lib/useUser';

export const LogoutButton = ({ style }) => {
  const isTextStyle = style === 'text';
  const { user, mutateUser } = useUser();
  const router = useRouter();

  return user?.isLoggedIn ? (
    isTextStyle ? (
      <button
        className="anchor-subtle"
        preventDefault={true}
        onClick={async () => {
          posthog.reset();
          mutateUser(
            await fetchJson('/api/account/logout', { method: 'POST' }),
            false
          );
          // Redirect to homepage
          router.push('/');
        }}
      >
        Sign Out
      </button>
    ) : (
      <Button
        href="/api/account/logout"
        preventDefault={true}
        theme={'button-blue'}
        iconSrc={signout}
        onClick={async () => {
          posthog.reset();
          mutateUser(
            await fetchJson('/api/account/logout', { method: 'POST' }),
            false
          );
          // Redirect to homepage
          router.push('/');
        }}
      >
        Sign Out
      </Button>
    )
  ) : null;
};

export default LogoutButton;
