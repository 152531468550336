'use client';

import Img from 'next/image';
import Modal from 'react-modal';

import LogoutButton from 'components/account/buttons';
import Anchor from 'components/typography/anchor';
import menuIcon from 'images/icons/ellipsis.svg';
import closeIcon from 'images/icons/circle-x.svg';
import config from 'siteconfig';

export const NavToggle = ({ onClick, isOpen }) => {
  const icon = isOpen ? closeIcon : menuIcon;
  return (
    <button onClick={onClick} className="absolute right-4 top-4 dark:invert">
      <Img height="24" width="24" src={icon} alt={'Nav Toggle'} />
    </button>
  );
};

export const NavModal = ({ navIsOpen, closeModalFn }) => {
  // The test environment doesn't have a DOM, so we need to mock this.
  // but doing so in the tests breaks the tests, so we do it here.
  // See header.test.js for more info.
  if (process.env.NODE_ENV !== 'test') {
    if (typeof window !== 'undefined') {
      // App Router
      Modal.setAppElement(document.getElementById('root'));
    } else {
      // Pages Router
      Modal.setAppElement('#root');
    }
  }
  return (
    <Modal
      isOpen={navIsOpen}
      onRequestClose={closeModalFn}
      className={
        'fixed right-0 h-screen max-w-80 overflow-scroll bg-white px-2 shadow-xl dark:bg-stone-950/80'
      }
      overlayClassName={
        'fixed inset-0 bg-gradient-to-tr from-purple-800/80 to-fuchsia-900/80 transition-opacity duration-300 z-10'
      }
      contentLabel="Navigation Modal"
    >
      {/* Tailwind: .ReactModal__Overlay--before-close .ReactModal__Overlay--after-open */}
      <NavToggle onClick={closeModalFn} isOpen={navIsOpen} />

      <nav aria-label="secondary">
        <Anchor className="button-navigation mt-16" href="/">
          Home
        </Anchor>
        <Anchor className="button-navigation" href="/premium">
          Upgrade to Premium
        </Anchor>
        <Anchor className="button-navigation" href="/premium/coins">
          Buy Coins
        </Anchor>
        <Anchor className="button-navigation" href="/petitions">
          Petitions
        </Anchor>
        <Anchor className="button-navigation" href="/letters">
          Open Letters
        </Anchor>
        <Anchor className="button-navigation" href="/officials">
          Officials
        </Anchor>
        <Anchor className="button-navigation" href="/guide">
          Guide
        </Anchor>
        <Anchor className="button-navigation" href="/about">
          Help
        </Anchor>
        <Anchor className="button-navigation" href="/news">
          News
        </Anchor>
        <Anchor
          className="button-navigation"
          href={config.shopUrls.cottonBureau}
        >
          Shop
        </Anchor>
        <span className="m-2 block border-t border-stone-200 pt-2 text-sm uppercase tracking-wider dark:border-stone-700">
          Text Resistbot on
        </span>
        <Anchor className="button-navigation" href={config.channelUrls.apple}>
          Apple Messages
        </Anchor>
        <Anchor
          className="button-navigation"
          href={config.channelUrls.telegram}
        >
          Telegram
        </Anchor>
        <Anchor
          className="button-navigation"
          href={config.channelUrls.instagram}
        >
          Instagram
        </Anchor>
        <Anchor
          className="button-navigation"
          href={config.channelUrls.messenger}
        >
          Messenger
        </Anchor>
        <Anchor className="button-navigation" href={config.channelUrls.sms}>
          SMS (50409)
        </Anchor>
        <LogoutButton />
      </nav>
    </Modal>
  );
};
